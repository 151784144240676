<template>
  <div class="m-4 rental-list">
    <section class="mb-2">
      <b-field type="is-primary">
        <b-select icon="account" v-model="borrower" rounded>
          <option
            v-for="option in users"
            :value="option.id"
            :label="option.name"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
    </section>
    <b-table
      striped
      hoverable
      narrowed
      :data="rentals"
      scrollable
      sticky-header
    >
      <b-table-column
        field="book_title"
        label="タイトル"
        v-slot="props"
        sticky
        cell-class="rental-list-truncate"
      >
        <span :title="displayTitle(props.row.book.title)">
          {{ displayTitle(props.row.book.title) }}
        </span>
      </b-table-column>

      <b-table-column
        field="user_id"
        label="借りている人"
        v-slot="props"
        width="160"
      >
        {{ displayUserName(props.row.user_id) }}
      </b-table-column>

      <b-table-column
        field="lent_at"
        label="貸出日時"
        v-slot="props"
        width="180"
      >
        {{ displayDate(props.row.lent_at) }}
      </b-table-column>

      <b-table-column
        field="returned_at"
        label="返却日時"
        v-slot="props"
        width="180"
      >
        <span v-if="props.row.returned_at">
          {{ displayDate(props.row.returned_at) }}
        </span>
        <b-button
          type="is-primary is-light"
          label="返却"
          icon-left="undo"
          size="is-small"
          v-else-if="+props.row.user_id === +currentUserId"
          @click="returnBook(props.row.id, props.row.book.title)"
        />
      </b-table-column>
      <template #empty>
        <div class="section has-text-centered">貸出履歴がありません</div>
      </template>
    </b-table>
    <b-loading is-full-page v-model="loading"></b-loading>
  </div>
</template>

<script>
import dayjs from "dayjs";
import fetchMixin from "../fetch-mixin";
import store from "../store";
import storeMixin from "../store-mixin";

export default {
  name: "RentalList",
  mixins: [storeMixin, fetchMixin],
  data() {
    return {
      borrower: +this.$store.getters["auth/currentUserId"],
    };
  },
  props: {},
  computed: {
    rentals() {
      return this.$store.state.my.rentals.filter(
        (v) => this.borrower === 0 || +v.user_id === +this.borrower
      );
    },
    users() {
      return this.$store.state.my.usernames.map((v) =>
        v.id === 0
          ? {
              id: v.id,
              name: "全員",
            }
          : v
      );
    },
    currentUserId() {
      return +this.$store.getters["auth/currentUserId"];
    },
  },
  methods: {
    displayDate(date) {
      return date === undefined || date === null
        ? ""
        : dayjs(date).format("YYYY/MM/DD HH:mm");
    },
    displayUserName(userId) {
      const index = this.$store.state.my.usernames.findIndex(
        (v) => +v.id === +userId
      );
      if (index === -1) {
        return "不明";
      }
      return this.$store.state.my.usernames[index].name;
    },
    displayTitle(title) {
      return title;
    },
    async returnBook(rentalId, title) {
      this.$buefy.dialog.confirm({
        title: "確認",
        message: `${title}を返却します`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.store("my/returnBook", { rentalId });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  },
  async mounted() {
    try {
      await this.fetch("my/listUserName");
    } catch (e) {
      console.log(e);
    }
  },
  async beforeRouteEnter(to, _from, next) {
    store.commit("setLoading", true);
    try {
      await store.dispatch("my/listRental");
      store.commit("setLoading", false);
      next();
    } catch (e) {
      console.log(e);
      store.commit("setError", e.data ? e.data.detail : e.message);
      store.commit("setLoading", false);
      if (e.status) {
        if (e.status === 401) {
          next({ name: "login" });
        }
      }
    }
  },
};
</script>

<style lang="scss">
.rental-list-truncate {
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
